.add-exercise-container {
  margin-top: 30px;
}

.detail-overview-container {
  margin-bottom: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.description-detail-container {
  margin-top: 5px;
}

.session-info-container {
  box-sizing: border-box;
  background-color: #eeeeee;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.comment-section-container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.comment-section-header {
  font-size: large;
  font-weight: bold;
}

.edit-session-info-button {
  float: right;
  cursor: pointer;
}

.date-input-field {
  cursor: pointer;
}

.delete-session-button {
  float: right;
  background-color: #ff4444;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 5px 10px;
  margin-bottom: 5px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 12px;
  cursor: pointer;
}

.save-info-button {
  display: block;
  background-color: #4caf50;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 5px 10px;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.add-exercise-button {
  background-color: #4caf50;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 5px 10px;
  margin-top: 5px;
  margin-left: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}

.show-more-text {
  text-decoration: underline;
  cursor: pointer;
  font-size: 10px;
}

.detail-overview-inner-container {
  box-sizing: border-box;
  width: 100%;
  margin: 10px;
  padding: 10px;
}

.text-area-container {
  width: 100%;
}

textarea {
  box-sizing: border-box;
  width: 100%;
}

@media only screen and (min-width: 640px) {
  .detail-overview-inner-container {
    width: 600px;
  }
}
