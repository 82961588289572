.exercise-unit-detail-container {
  margin-bottom: 20px;
}

.exercise-info-container {
  display: flex;
  width: 100%;
  border-bottom: 1px solid black;
  margin-bottom: 10px;
}

.exercise-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.exercise-name-comment-container {
  margin-bottom: 5px;
}

.edit-exercise-button {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #707070;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
  margin-bottom: 5px;
}

.delete-exercise-button {
  background-color: #ff4444;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  height: min-content;
  margin-left: 10px;
}

.update-exercise-button {
  background-color: #4caf50;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 5px 10px;
  margin-bottom: 5px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 12px;
  cursor: pointer;
  height: min-content;
  margin-left: 10px;
}

.exercise-name {
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
}

.no-sets-yet {
  margin-left: 10px;
}

.exercise-unit-comment {
  font-size: 12px;
  margin: 0px;
  margin-left: 5px;
}
