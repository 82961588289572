.set-detail-container {
  margin-bottom: 3px;
  width: 100%;
  position: relative;
}

.edit-set-button {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  background-color: #f2f2f2;
  color: #707070;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.delete-set-button {
  background-color: #ff4444;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
}

.update-set-button {
  background-color: #4caf50;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
}

.weight-and-repetition-container {
  display: inline-block;
  min-width: 135px;
}

.weight-number {
  display: inline-block;
  text-align: right;
  min-width: 45px;
}

.big-number {
  font-size: 24px;
}

.weight-unit {
  /* position: absolute; */
  font-size: 24px;
}

.times {
  font-size: 24px;
  /* left: 100px;
  position: absolute; */
}

/* Remove the button parts of the input fields with number type */
input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
/* ---- */
