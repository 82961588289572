.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
}

.filter-container {
  display: flex;
  align-items: center;
}

.session-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.checkbox {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 3px;
  border: 2px solid black;
  outline: none;
  cursor: pointer;
}

.checkbox:checked {
  background-color: #2196f3;
  border-color: #2196f3;
}

.new-session-button {
  background-color: #4caf50;
  border-radius: 4px;
  margin: 5px;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.load-more-button {
  background-color: white;
  border-radius: 4px;
  border: orange solid 2px;
  color: orange;
  padding: 15px 32px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
}

.more-to-load {
  background-color: orange;
  color: white;
  cursor: pointer;
}

.more-to-load:hover {
  background-color: darkorange;
}

.new-session-button:hover {
  background-color: #4ccf50;
}
