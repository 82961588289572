.new-set-container {
}

.weight-input {
  width: 40px;
}

.add-set-button {
  background-color: #4caf50;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 3px 6px;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  margin-left: 3px;
  cursor: pointer;
}

.repetition-input {
  width: 40px;
  margin-left: 3px;
}
