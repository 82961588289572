.session-container {
  border-radius: 4px;
  background-color: white;
  padding: 5px;
}

.session-container-wrap {
  padding-top: 2px;
  box-shadow: 1px 1px 1px gray;
  margin: 5px;
  border-radius: 4px;
  border: 1px solid lightgray;
  cursor: pointer;
}
.personal-session-container-wrap {
  padding-top: 3px;
  padding-left: 2px;
  border-radius: 4px;
  border: 0px solid gray;
  background: linear-gradient(to bottom right, green, 20%, white);
}

.session-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.session-header {
  margin: 0px;
  display: inline-block;
  margin-right: 10px;
}

.session-link {
  text-decoration: none;
}

.username-container {
  padding-bottom: 10px;
}

.username-text {
  color: blue;
}

.username-text:active {
  color: darkblue;
}

.username-text:hover {
  text-decoration: underline;
}

.like-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 4px;
  border-top: 1px solid lightgrey;
  background-color: white;
}

.personal-like-container {
  justify-content: end;
}

.like-button {
  background-color: white;
  border-radius: 4px;
  border: 1px solid gray;
  color: black;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.description-container {
  font-size: 14px;
  margin-left: 3px;
  margin-bottom: 10px;
  color: #474747;
}

@media only screen and (min-device-width: 480px) {
  .session-container {
    max-width: 400px;
  }
}
