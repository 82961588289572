.navbar-container {
  display: flex;
  justify-content: space-evenly;
  height: 50px;
  border-bottom: solid black 2px;
  margin: 5px;
}

@media only screen and (max-width: 640px) {
  .navbar-container {
    justify-content: space-between;
  }
}

.logo {
  height: 40px;
  border: solid black;
  margin-bottom: 5px;
}

.dropdown-menu {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  max-width: 100px;
  width: 100%;
}
.dropdown-button {
  background-color: white;
  border-radius: 4px;
  border: solid 1px black;
  color: black;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  background-color: #f9f9f9;
  min-width: 60px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content-expanded {
  display: block;
}

.menu-button {
  background-color: orange;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 10px 0px;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  max-width: 100px;
  width: 100%;
  cursor: pointer;
}

.home-button {
  background-color: #4caf50;
}

.logout-button {
  background-color: #ff4444;
}

.logout-button:hover {
  background-color: #ff2244;
}
