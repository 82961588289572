.password-reset-confirm-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh; /* Full viewport height */
  padding-top: 20px; /* Add padding at the top to push the form down slightly */
}

h1 {
  color: #333; /* Dark grey color for the title */
  font-size: 2.5rem; /* 40px font size for the title */
  margin-bottom: 20px; /* Space below the title */
}

.reset-confirm-button {
  background-color: #4caf50;
  border-radius: 4px;
  border: none;
  color: white;
  padding: 10px 0px;
  margin-top: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  max-width: 150px;
  width: 100%;
  cursor: pointer;
}
