.search-overview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-frame {
  border: 2px solid #000;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
