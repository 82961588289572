.user-details-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}

.user-info-container {
  box-sizing: border-box;
  background-color: #eeeeee;
  margin-bottom: 5px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  padding: 5px;
}

.editing-user-info-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #eeeeee;
  margin-bottom: 5px;
  border-radius: 10px;
  max-width: 400px;
  width: 100%;
  padding: 5px;
}

.editing-user-info-container input {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 16px;
}

.editing-user-info-container textarea {
  margin-top: 5px;
  margin-bottom: 10px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 16px;
}

.user-name-container {
  display: block;
  font-size: 30px;
}

.unit-system-container {
  display: block;
}

.user-statistics-container {
  display: flex;
  justify-content: space-around;
  border-top: solid 1px black;
  padding-top: 5px;
}

.profile-load-more-button {
  background-color: white;
  border-radius: 4px;
  border: orange solid 2px;
  color: orange;
  padding: 15px 32px;
  margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 20px;
  max-width: 400px;
  width: 100%;
}

.profile-more-to-load {
  background-color: orange;
  color: white;
  cursor: pointer;
}

.session-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 400px;
}

.follow-button {
  float: right;
  cursor: pointer;
}

.unfollow-button {
  float: right;
  cursor: pointer;
}

.edit-profile-button {
  float: right;
  cursor: pointer;
}
